import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  /* Heading, */ Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  StatLabel,
  Tab,
  TabList,
  Tabs,
} from '@chakra-ui/react';
import { AppTemplate } from '../../shared/ui/AppTemplate';
import { sendLeadToVakas, sendLeadToVakasAllLeads, sendLeadToVakasNewLead } from '../../shared/api/auth';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../shared/enums/routes';
import { Typography } from 'antd';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

function isNumeric(value: string) {
  if (value === '') {
    return true;
  }

  return /^-?\d+$/.test(value);
}

const utm_source = 'rozygryshr.missis-laser.ru';
const utm_term = 'РозыгрышМарт';
const utm_campaign = 'РозыгрышМарт';
const utm_medium = 'referral';
const budget = 1990;

export const Home = observer(() => {
  const [error, setError] = useState<boolean>();
  const [errorText, setErrorText] = useState<string | null>(null);
  //* ==================
  const [username, setUserName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  //* ==================
  const [friend_name, setFriend_Name] = useState<string>('');
  const [friend_phone, setFriend_Phone] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [tabIndex, setTabIndex] = useState<string>('none');

  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
      authDomain: 'missis-laser.firebaseapp.com',
      projectId: 'missis-laser',
      storageBucket: 'missis-laser.appspot.com',
      messagingSenderId: '514174044584',
      appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
      measurementId: 'G-QTNN569ZLV',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, 'rozygryshr');
  }, []);

  //* ==================
  const history = useHistory();

  const handleTabsChange = (index: number) => {
    setTabIndex(index === 0 ? 'old' : 'new');
    setError(false);
  };

  const onSubmit = async () => {
    try {
      await sendLeadToVakas({
        friend_name: username,
        friend_phone: '+7' + phone,
        budget,
        city,
        name: friend_name,
        phone: '+7' + friend_phone,
        utm_term,
        utm_source,
        utm_campaign,
        utm_medium,
      }).then(async ({ data }) => {
        console.log('sendLeadToVakas', data);
        tabIndex === 'new'
          ? await sendLeadToVakasNewLead({
              city,
              name: username,
              phone: '+7' + phone,
              utm_term,
              utm_source,
              utm_campaign,
              utm_medium,
            }).then(
              async () =>
                await sendLeadToVakasAllLeads({
                  city,
                  name: username,
                  phone: '+7' + phone,
                  utm_term,
                  utm_source,
                  utm_campaign,
                  utm_medium,
                })
            )
          : await sendLeadToVakasAllLeads({
              city,
              name: username,
              phone: '+7' + phone,
              utm_term,
              utm_source,
              utm_campaign,
              utm_medium,
            });
        history.push(Routes.Success);
      });
    } catch (error) {
      console.log(error);
      history.push(Routes.Error);
    }
  };

  return (
    <AppTemplate
      headerTitle='Тест драйв'
      headerBody={
        <StatLabel style={{ textAlign: 'center', fontSize: 15 }}>
          🎁Сеть клиник лазерной эпиляции МИССИС ЛАЗЕР устраивает БЕСПЛАТНЫЙ онлайн <b>РОЗЫГРЫШ</b> 10ти сертификатов
          номиналом "3000" рублей!
          <br />
          <br />
          <b>Как выиграть?</b>
          <br /> Просто зарегистрироваться на участие и дождаться онлайн трансляцию.
          <br />
          <br />
          <b>Где будет проходить розыгрыш?</b>
          <br /> В официальном канале телеграм, доступ к которому сразу откроется после регистрации на участие.
          <br />
          <br />
          <b>Как будут определяться победители?</b>
          <br /> С помощью рандомайзера в прямом эфире будут выбраны номера победителей.
          <br />
          <br />
          <b>Как происходит регистрация?</b>
          <br /> Вы просто заполняете свои имя и номер телефона, и указываете имя и номер телефона одной из своих
          подруг,{' '}
          <StatLabel style={{ color: 'green', fontSize: 15, display: 'inline' }}>
            <b>КОТОРАЯ НЕ БЫЛА РАНЕЕ НАШИМ КЛИЕНТОМ</b>
          </StatLabel>
          . В случае победы, Ваша подруга получает такой же приз!
          <br />
          <br />
          <b>
            🥰 Регистрируйтесь прямо сейчас и выигрывайте!
            <br />
            Количество участников на розыгрыш ограничено*
          </b>
          <br />
        </StatLabel>
      }
      //headerBody={`Акция для новых клиентов только в Январе!\nЛазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб! Пока есть возможность - оставляйте заявку и приходите на процедуру! Ждём Вас!`}
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack spacing='2rem'>
          <Stack spacing='1rem'>
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Ваши контактные данные
            </Typography.Title>
            <InputGroup>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Введите ваше имя'
                value={username}
                onChange={e => {
                  setUserName(e.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Введите ваш телефон'
                value={phone}
                onChange={event => {
                  isNumeric(event.target.value) && setPhone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
          </Stack>
          <Typography.Title level={5} style={{ textAlign: 'center' }}>
            Укажите, были ли Вы у нас в клинике
          </Typography.Title>
          <Tabs
            style={{ alignItems: 'center' }}
            variant='soft-rounded'
            colorScheme='green'
            index={tabIndex === 'old' ? 0 : tabIndex === 'new' ? 1 : 99}
            onChange={handleTabsChange}
          >
            <TabList style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
              <Tab style={{ borderWidth: 2, marginRight: 4 }}>Уже была</Tab>
              <Tab style={{ borderWidth: 2, marginLeft: 4 }}>Еще не была</Tab>
            </TabList>
            {/* <TabPanels>
              <TabPanel>
                <p>Вы уже записывались к нам ранее</p>
              </TabPanel>
              <TabPanel>
                <p>Вы еще не записывались к нам ранее</p>
              </TabPanel>
            </TabPanels> */}
          </Tabs>
          <Stack spacing='1rem'>
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Контактные данные вашей подруги/друга
            </Typography.Title>
            <InputGroup>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Введите имя подруги'
                value={friend_name}
                onChange={e => {
                  setFriend_Name(e.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Введите телефон подруги'
                value={friend_phone}
                onChange={event => {
                  isNumeric(event.target.value) && setFriend_Phone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup>
              <Select
                value={city}
                onChange={event => {
                  setCity(event?.target.value);
                  setError(false);
                }}
                placeholder='Выберите город'
              >
                <option value='Москва'>Москва</option>
                <option value='Санкт-Петербург'>Санкт-Петербург</option>
                <option value='Казань'>Казань</option>
                <option value='Уфа'>Уфа</option>
                <option value='Тверь'>Тверь</option>
              </Select>
            </InputGroup>
          </Stack>

          {error && (
            <Stack spacing='1rem' style={{ marginTop: 12 }}>
              {errorText && (
                <Alert status='error'>
                  <AlertIcon />
                  {errorText}
                </Alert>
              )}
            </Stack>
          )}

          <Button
            //disabled={city === "" || friend_phone === "" || friend_name === "" || phone === "" || username === ""}
            onClick={() => {
              if (
                city === '' ||
                friend_phone === '' ||
                friend_name === '' ||
                phone === '' ||
                username === '' ||
                tabIndex === 'none'
              ) {
                setError(true);
                setErrorText(
                  city === ''
                    ? 'Вы не указали город'
                    : friend_phone === ''
                    ? 'Вы не указали номер подруги/друга'
                    : friend_name === ''
                    ? 'Вы не указали имя подруги/друга'
                    : phone === ''
                    ? 'Вы не указали свой номер'
                    : username === ''
                    ? 'Вы не указали своё имя'
                    : tabIndex === 'none'
                    ? 'Укажите, были ли вы у нас ранее'
                    : null
                );
              } else {
                onSubmit();
              }
            }}
            mt='24px'
            w='100%'
            h='60px'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
          >
            Хочу участвовать
            <br />в розыгрыше
          </Button>
        </Stack>
      </Box>
    </AppTemplate>
  );
});
